import {
    buildMapToField,
    handleQueryResolve
} from '../utils'

const uniqBy = require('lodash/uniqBy')

export default function (PatientID) {
  // classic JS with hoist and scope
  // FIXME break those function to their own modules and call them with this[query]
  const _vm = this

  return Promise.all([
    queryPatientProfile(PatientID),
    queryPharmacyInfo(),
    queryPatientMedication(PatientID),
    queryPatientDoctor(PatientID),
  ]).then(patientRecords => patientRecords.reduce((patientData, patientRecord) => ({
    ...patientData,
    ...patientRecord,
  }), {}))

  ///
  function queryPatientMedication(PatientID) {
    // extract Unqiue DINs of Patient
    // walid inserted nexxsys querey
    return _vm.query(`
      SELECT
      "DBA"."Drug"."DIN"
      FROM "DBA"."Prescription" JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id"
      WHERE
      DBA.Prescription.PatientId = @PatientID
      GROUP BY "DBA"."Drug"."DIN"
      `, [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID, // to support like
    }])
      .then(handleQueryResolve)
      .then(results => {
        // get records of each PatientDIN
        const DINS_LIST = Object.values(results).reduce((cur, record) => {
          if (record.DIN && !isNaN(record.DIN)) {
            cur.push(record.DIN)
          }

          return cur
        }, [])

        // if an empty Array, don't proceed and handle it as a promise error
        if (DINS_LIST.length) {
          return DINS_LIST
        }

        return null
      })
      .then(listOfPatientDINs => {
        if (listOfPatientDINs && listOfPatientDINs.length) {
          return _vm.query(`
                        SELECT  * FROM
                        (SELECT 
                            "DBA"."Prescription"."RxNumber" AS "RxNumber",
                            "DBA"."Prescription"."ActiveFlag",
                            "DBA"."Prescription"."Status",
                            "DBA"."Prescription"."FillDate",
                            CONVERT(CHAR(20),"DBA"."Prescription"."WB_Date",112) AS "WB_Date",
                            "DBA"."Prescription"."DaysSupply",
                            "DBA"."Prescription"."InstructionText",
                            "DBA"."Prescription"."QuantityAuthorized",
                            "DBA"."Prescription"."FillQuantity",
                            "DBA"."Drug"."GenericName",
                            "DBA"."Drug"."DIN",
                            "DBA"."Drug"."TradeName",
                            "DBA"."Drug"."Strength",
                            "DBA"."Drug"."DrugColour",
                            "DBA"."Drug"."DrugSchedule",
                            "DBA"."DrugForm"."Description",
                            "DBA"."Prescription"."PatientId",
                            "DBA"."PrescriptionDosset"."Bkf",
                            "DBA"."PrescriptionDosset"."Noon",
                            "DBA"."PrescriptionDosset"."Sup",
                            "DBA"."PrescriptionDosset"."Bed",
                            "DBA"."RTCD0"."TC_DESC",
                            RANK() OVER (PARTITION BY "DBA"."Drug"."GenericName", "DBA"."Drug"."Strength" ORDER BY WB_Date DESC) AS Rank
                            FROM ( ( ( ( "DBA"."Prescription" JOIN "DBA"."Drug" ON "DBA"."Prescription"."DrugId" = "DBA"."Drug"."Id" ) JOIN "DBA"."DrugForm" ON "DBA"."Drug"."FormCode" = "DBA"."DrugForm"."Code" ) JOIN "DBA"."Doctor" ON "DBA"."Prescription"."DoctorId" = "DBA"."Doctor"."Id" AND (FillDate > DATEADD( month,  -13, GetDate() )  OR WB_Date > DATEADD( month,  -13, GetDate() )) AND "DBA"."Prescription"."PatientId" = @PatientID ) JOIN "DBA"."PrescriptionDosset" ON "DBA"."Prescription"."Id" = "DBA"."PrescriptionDosset"."RxId" ) JOIN "DBA"."RTCD0" ON "DBA"."Drug"."TC" = "DBA"."RTCD0"."TC") AS DT
                        WHERE Rank = 1
                        ORDER BY FillDate
                  `, [{
            param: 'PatientID',
            type: 'sql.Int',
            value: PatientID, // to support like
          }])
        }

        // empty medications
        return {
          medications: [],
        }
      },

      // handle empty DIN LIST
      () => ({
        medications: [],
      }))
      .then(handleQueryResolve)
      .then(medicationResults => {
        const listOfNonRepeatedMeds = uniqBy(medicationResults, 'DIN').map((record, index) => ({
          // [`ID`]: key,
          DIN: record.DIN,
          RxNumber: record.RxNumber,

          // RxDate: record.FillDate,
          RxDate: record.FillDate === null ? record.WB_Date : record.FillDate,
          QtyDispense: record.FillQuantity,
          QtyRemain: '',
          Days: Math.trunc(parseInt(record.DaysSupply)),
          'RX?': record.DrugSchedule == '01' ? 'Yes' : '',
          'NHP?': record.DrugSchedule == '03' ? 'Yes' : '',
          GenericName: record.GenericName,
          'OTC?': record.DrugSchedule == '04' ? 'Yes' : '',
          onHold: record.Status == 'HOL' ? 'Yes' : '',
          Status: record.Status,
          MED: record.TradeName,
          'MED STR': record.Strength,
          FORM: record.Description,
          SIG: record.InstructionText,
          INDICATION: record.TC_DESC ? [record.TC_DESC.toUpperCase()] : '',
          'MED AM QTY': record.Bkf,
          'MED LUNCH QTY': record.Noon,
          'MED DINNER QTY': record.Sup,
          'MED BEDTIME QTY': record.Bed,
          'MED OTHER QTY': '',
          'ADHERENCE YES': '',
          'ADHERENCE NO': '',
          'PATIENT COMMENT': '',
          'MED PHARMACIST NOTES': '',
          'COMMENTS FOR MEDSCHECK RECORD': '',
        }))

        return {
          medications: uniqBy(listOfNonRepeatedMeds, med => [med.GenericName, med['MED STR']].join()),
        }
      })
  }

  // TODO OCP Pharmacist's # is under "USERS"
  function queryPharmacyInfo() {
    return _vm.query(`
          SELECT "ph"."name" as PharmacyName,
          "ad"."line1" as PharmacyAddress,
          ad.cityname as PharmacyCity,
          ps.Code as PharmacyProvince,
          "ad"."phone" as PharmacyPhone,
          "ad"."fax" as PharmacyFax,
          ad.PostalCode AS PharmacyPostal
          FROM "pharmacy" AS "ph" JOIN "addressrole" AS "adr"
          ON "ph"."id" = "adr"."entityid" JOIN "address" AS "ad"
          ON "ad"."id" = "adr"."addressid"
          LEFT OUTER JOIN "ProvinceState" AS "ps" ON "ad"."ProvinceName" = "ps"."Name"
          WHERE "adr"."entitycode" = 'PHA'
          AND "adr"."type" = 'BUS'
          AND "ph"."type" = 'HOS'
      `, [])
      .then(handleQueryResolve)
      .then(recordset =>

      // const MapValuesTo = {
      //     PharmacyName: 'PharmacyName',
      //     Address: 'PharmacyAddress',
      //     City: 'PharmacyCity',
      //     Province: 'PharmacyProvince',
      //     Postal: 'PharmacyPostal',
      //     Fax: 'PharmacyFax',
      //     Phone: 'PharmacyPhone'
      // };

      // const whiteList = Object.keys(MapValuesTo);

      // let pharmacyInfo = recordset.reduce((cur, row) => {

      //     if (whiteList.indexOf(row['sParameterName']) == -1 || !row['sParameterValue']) {
      //         return cur;
      //     }

      //     return {
      //         ...cur,
      //         [MapValuesTo[row['sParameterName']]]: row['sParameterValue']
      //     }
      // }, {});

        buildMapToField(recordset[0]))
  }

  function queryPatientProfile(PatientID) {
    return _vm.query(`
      SELECT "patient"."Id" as PatientID,
      "patient"."LastName" as LastName,
      "patient"."FirstName" as FirstName,
      "patient"."ProvincialHealthNumber",
      "address"."Phone" as HomePhone,
      address.AlternatePhoneNumber as MobilePhone,
      address.Email as EMail,
      "patient"."DateOfBirth" as DOB,
      "patient"."gender" as Gender,
      "address"."Line1" as Address,
      "address"."CityName" as City,
      "address"."PostalCode" as Postal,
      "address"."ProvinceName" as Province,
      "patient"."lastRXDate" as LastVisit
      FROM "dba"."patient"
      LEFT OUTER JOIN "dba"."preference"
      ON "preference"."code" = 'P03'
      AND "preference"."entitycode" = 'PAT'
      AND "preference"."entityId" = "patient"."id"
      LEFT OUTER JOIN "DBA"."grouppatient" ON(
      "Patient"."Id" = "GroupPatient"."PatientId"
      AND "GroupPatient"."DefaultFlag" = 1) LEFT OUTER JOIN "DBA"."groups"
      ON "Groups"."id" = "GroupPatient"."GroupId"
      ,"dba"."addressrole"
      ,"dba"."address"
      WHERE "addressrole"."entityid" = "patient"."id"
      AND "addressrole"."entitycode" = 'PAT'
      AND "address"."id" = "addressrole"."addressid"
      AND "addressrole"."primaryflag" = 1
      AND "patient"."mergedflag" = 0
      AND "patient"."Id" = @PatientID
          `, [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID,
    }])
      .then(handleQueryResolve)
      .then(recordset => buildMapToField(recordset[0]))
  }

  // Primary Provider is the top doctor
  function queryPatientDoctor(PatientID) {
    return _vm.query(`
      SELECT DISTINCT TOP 1
          "d"."LastName" as DoctorLastName,
          "d"."FirstName" as DoctorFirstName,
          "a"."Phone" as DoctorPhone,
          "a"."Line1" as DoctorAddress,
          "a"."Fax" as DoctorFax,
          "a"."CityName" as DoctorCity,
          "a"."ProvinceName" as DoctorProvince,
          "a"."PostalCode" as DoctorPostal,
          "dp"."Description",
          "d"."Id",
          "d"."PrescriberId" as CPSO,
          "DBA"."Prescription"."PatientId" as PatientID,
      COUNT("d"."PrescriberId") as RXperProvider
      FROM "DBA"."Doctor" AS "d" LEFT OUTER JOIN "DBA"."DoctorPractice" AS "dp" ON "d"."Practicecode" = "dp"."code",
      "DBA"."AddressRole" AS "ar",
      "DBA"."Address" AS "a",
      "DBA"."Doctor" AS "d" INNER JOIN "DBA"."Prescription" ON "d"."Id" = "DBA"."Prescription"."DoctorId"
      WHERE "ar"."EntityId" = "d"."Id" AND "ar"."AddressId" = "a"."Id" AND "ar"."EntityCode" = 'DOC' AND "DBA"."Prescription"."FillDate" > DATEADD(year, -1, GetDate()) AND PatientID = @PatientID
      GROUP BY
          "d"."LastName",
          "d"."FirstName",
          "a"."Phone",
          "a"."Line1",
          "a"."Fax",
          "a"."CityName",
          "a"."ProvinceName",
          "a"."PostalCode",
          "dp"."Description",
          "d"."Id",
          "d"."PrescriberId",
          PatientID
      ORDER BY RXperProvider DESC
      `, [{
      param: 'PatientID',
      type: 'sql.Int',
      value: PatientID,
    }])
      .then(handleQueryResolve)
      .then(recordset => {
        if (recordset.length) {
          // reverted back to the old code due that it breaks
          // primary Telephone and Fax
          return buildMapToField(recordset[0])
        }

        return {}
      })
  }
}
